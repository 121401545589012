.animated {
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  z-index: 9999;
}

.loader {
  -webkit-animation-name: horizontalProgressBar;
  animation-name: horizontalProgressBar;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  background: #dc8a00;
  height: 3px;
  left: 0;
  position: fixed;
  top: 0;
  width: 0;
  z-index: 9999 !important;
}

@keyframes horizontalProgressBar {
  0% {
    width: 0;
  }
  25% {
    width: 22%;
  }
  50% {
    width: 55%;
  }
  75% {
    width: 83%;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes horizontalProgressBar /* Safari and Chrome */ {
  0% {
    width: 0;
  }
  25% {
    width: 22%;
  }
  50% {
    width: 55%;
  }
  75% {
    width: 83%;
  }
  100% {
    width: 100%;
  }
}
