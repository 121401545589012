// Bootstrap color overrides are imported from below _variables.scss file
// For documentation visit: https://getbootstrap.com/docs/4.0/getting-started/theming/
// For online editor visit: https://bootstrap.build/app/v4.1.1/

@import '_variables.scss';
@import '~bootstrap/scss/bootstrap';
@import '~lato-font/scss/_public-api';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

@font-face {
  font-family: Lato;
  src: url(~lato-font/fonts/lato-normal/lato-normal.woff);
}

a,
.page-link {
  color: #dc8a00;
}

html,
body,
#root {
  height: 100% !important;
}

pre {
  background-color: #141414;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  font-size: 85%;
  line-height: 1.45;
  white-space: pre-wrap;
  word-wrap: break-word;
  border-radius: 5px;
  color: gray;
}

.pre-select {
  user-select: all;
}

.markdown-body img {
  max-width: 100%;
}

video {
  max-width: 100%;
}

.dropdown-menu {
  min-width: 100%;
  z-index: 9999;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown {
  display: flex;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.icon {
  width: 30px;
  height: 30px;
}

.recharts-tooltip-wrapper,
.recharts-tooltip-cursor,
.recharts-default-tooltip,
.recharts-tooltip-item {
  border: none !important;
  background-color: $black !important;
  color: $white !important;
}

.list-group-small,
.list-group-small .list-group-item {
  font-size: medium;
}

.card-header,
.list-group-small .list-group-item {
  padding: map-get($spacers, 1);
}

.icon-small,
.list-group-small .icon {
  width: 20px;
  height: 20px;
}

.list-group-small .badge {
  font-size: x-small;
  font-weight: normal;
}

.list-group-item {
  background: #141414 !important;
}

.list-group-item-danger {
  color: #fff !important;
  background: #dc3545 !important;
}

.page-link,
.pagination {
  background: #141414 !important;
  border: none !important;
}

.list-group-item:hover,
.list-group-item:active,
.list-group-item-action:hover,
.list-group-item-action:active,
.page-link:hover,
.page-link:active {
  background: #101010 !important;
}

.list-group-item-danger:hover,
.list-group-item-danger:active,
.list-group-item-danger:focus {
  background: #c82333 !important;
}

.btn.disabled {
  cursor: default;
}

.user-select-none {
  user-select: none !important;
}

.custom-file-label {
  background: none;
  color: white;
  border: none;
}

.dark-card .card {
  margin-top: 0;
  background: #171717;
  border: none;
  transform: translateZ(0);
  position: relative;
  z-index: 2;
}

.dark-card .card-header {
  background: #171717;
  padding: 10px;
  color: white;
  border: none;
  border-bottom: 1px solid #232323;
  font-size: 14px;
}

.dark-card .card-title {
  font-size: 18px;
}

.dark-card .card-body {
  color: white;
  padding: 20px;
  margin-top: 0;
}

.octicon {
  display: none;
}
